/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGipfeltabelle = /* GraphQL */ `
  query GetGipfeltabelle($id: ID!) {
    getGipfeltabelle(id: $id) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const listGipfeltabelles = /* GraphQL */ `
  query ListGipfeltabelles(
    $filter: ModelGipfeltabelleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGipfeltabelles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datum
        gipfel
        hoehe
        climp
        start
        region
        owner
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      price
      image
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        price
        image
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        avatar {
          bucket
          region
          key
        }
        owner
      }
      nextToken
    }
  }
`;
