/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:7a0dcc0f-00fe-4c13-af29-1dea003ed2ba",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jmgatx5VE",
    "aws_user_pools_web_client_id": "38f5f9d28jukv1tggc052a1ajm",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ba66wiyyn5dkxj6rarendzsypi.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "gipfelbuch20072021d5ad79f3cbc940e694118cf0030ab150501-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
