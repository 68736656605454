import React from 'react'

//Eigentlich Sinnlos
//Der Header wird allerdings nicht erkannt und deswegen ist alles unter dem Header
// durch blank ist nochmal ein div in größe des Headers unter diesem welcher nicht gesehen wird
// aber dafür sorgt, dass die Komponenten "normal" angezeigt werden und nicht unter dem Header verschwinden

function Blank() {
    return (
        <div>
            <h3>no</h3>
        </div>
    )
}
export default Blank;