import * as React from 'react';
import './DataGrid.css';
import Amplify, {API, graphqlOperation } from '@aws-amplify/api';                  //import von aws-amplify || API und graphqloperations für fetchGipfel
import awsconfig from '../../aws-exports';              //import für die Einstellungen von amplify
import { createGipfeltabelle } from '../../graphql/mutations';
import { useState,  } from 'react';
import { IconButton, TextField } from '@material-ui/core';

//imports für denDatepicker
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { red, blue } from '@material-ui/core/colors';

//imports für das Dropdown
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//import für das Textfield mit Icon
import InputAdornment from '@material-ui/core/InputAdornment';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import HeightIcon from '@material-ui/icons/Height';
import RoomIcon from '@material-ui/icons/Room';
import ExploreIcon from '@material-ui/icons/Explore';
import FlagIcon from '@material-ui/icons/Flag';



Amplify.configure(awsconfig)                        // Konfiguration von Amplify

//Styling für das Dropdown
const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
}));



export default function RowAdd(){

  const [ showAddGipfel, setShowAddNewGipfel ] = useState(false)
  const AddGipfel = ({onUpload}) =>{
  const [gipfelData, setGipfelData] = useState({});

  //Start Variabeln Dropdown
    const classes = useStyles();
    const [aufstieg, setAufstieg] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setAufstieg(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    //Ende Variabelen für Dropdown

 //const für das Datum
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
        setSelectedDate(date);
  };

  function refreshPage(){ 
    window.location.reload(); 
  }


  const uploadGipfel = async () => {
    //Upload des neuen Gipfel
    console.log('gipfelData', gipfelData);
    const { gipfel, hoehe, climp, start, region } = gipfelData;
   // const datum = moment(startDate).format('YYYY-MM-DD');; //setzen des Datums gleich des startDate hierfür wird die moment.js libary verwendet die vorab installiert werden muss
    const datum =  moment(selectedDate).format('DD-MM-YYYY');

    const createGipfelInput ={
      datum,
      gipfel,
      hoehe,
      climp,
      start,
      region,
        
    }
    

    await API.graphql(graphqlOperation(createGipfeltabelle, {input:createGipfelInput}))
    onUpload();
  };
  
  //<TextField label ="gipfel" value={gipfelData.gipfel}  onChange={e => setGipfelData({ ...gipfelData, gipfel: e.target.value })}/>
  //   <TextField label ="hoehe" value={gipfelData.hoehe} onChange={e => setGipfelData({ ...gipfelData, hoehe: e.target.value })}/>
  //   <TextField label ="besteigung" value={gipfelData.climp} onChange={e => setGipfelData({ ...gipfelData, climp: e.target.value })}/>
  //   <TextField label ="start" value={gipfelData.start} onChange={e => setGipfelData({ ...gipfelData, start: e.target.value })}/>
  //   <TextField label ="region" value={gipfelData.region} onChange={e => setGipfelData({ ...gipfelData, region: e.target.value })}/> 


  return (
    <>
    <div className="newGipfel">

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
         
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    size="small"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
        </MuiPickersUtilsProvider>    

         <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label"> Besteigung</InputLabel>
                <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    IconComponent = {FlagIcon}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}                    
                    value={gipfelData.climp} 
                    onChange={e => setGipfelData({ ...gipfelData, climp: e.target.value })}                    
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={"Wandern"}>Wandern</MenuItem>
                    <MenuItem value={"Klettersteig"}>Klettersteig</MenuItem>
                    <MenuItem value={"Klettern"}>Klettern</MenuItem>
                    <MenuItem value={"Tourenski"}>Tourenski</MenuItem>
                    <MenuItem value={"Trailrunning"}>Trailrunning</MenuItem>
                    <MenuItem value={"Mountainbike"}>Mountainbike</MenuItem>
                </Select>
                </FormControl>

      <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Gipfel"
                value={gipfelData.gipfel}
                onChange={e => setGipfelData({ ...gipfelData, gipfel: e.target.value })}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FilterHdrIcon />
                        </InputAdornment>
                    ),
                }}
        />

      <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Höhe"
                value={gipfelData.hoehe} 
                onChange={e => setGipfelData({ ...gipfelData, hoehe: e.target.value })}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <HeightIcon />
                        </InputAdornment>
                    ),
                }}
      />

               
      

        <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Start"
                value={gipfelData.start} 
                onChange={e => setGipfelData({ ...gipfelData, start: e.target.value })}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <RoomIcon />
                        </InputAdornment>
                    ),
                }}
        />

        <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="Region"
                value={gipfelData.region} 
                onChange={e => setGipfelData({ ...gipfelData, region: e.target.value })}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <ExploreIcon />
                        </InputAdornment>
                    ),
                }}
        />

      
     
      <IconButton onClick={(event) => {uploadGipfel(); refreshPage();}}> 
        <PublishIcon style={{ color: blue[600] }} fontSize="large"/>
        
      </IconButton>

      <IconButton onClick={() =>{
        setShowAddNewGipfel(false)
      }}> 
        <CancelOutlinedIcon style={{ color: red[600] }} fontSize="medium"/>
      </IconButton>

    </div>

      <div> 
           
            
            </div>

        

      </>
    //value={aufstieg}
    //onChange={handleChange}


 //     <TextField
   //             className={classes.margin}
     //           id="input-with-icon-textfield"
       //         label="Besteigung"
         //       value={gipfelData.climp} 
           //     onChange={e => setGipfelData({ ...gipfelData, climp: e.target.value })}
             //   InputProps={{
               //     startAdornment: (
                 //       <InputAdornment position="start">
                   //         <FlagIcon />
                     //   </InputAdornment>
                 //   ),
              //  }}
       // />


    )

  }

    

    //Teil der gezeigt wird
    return (
        <>
        <div className="App">
            {
            showAddGipfel ? (
            <AddGipfel onUpload={() =>{
                setShowAddNewGipfel(false)
                
            }}          
            />
            ): 
            
            <IconButton onClick={() => setShowAddNewGipfel(true)}> <AddIcon fontSize="large"/> </IconButton>
            
            
        }
        </div>  
      
      
      </>
    );
    
    
}
