import * as React from 'react';
import './App.css';
import Amplify from 'aws-amplify';                  //import von aws-amplify || API und graphqloperations für fetchGipfel
import awsconfig from './aws-exports';              //import für die Einstellungen von amplify
import {withAuthenticator } from '@aws-amplify/ui-react';     //import der Funktionen die für auth genutzt werden
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Headers from './components/header/header3';
import DbTabelle from './components/dataGrid/DataGridDynamoDB';
import DbTabelleMobile from './components/dataGrid/DataGridDynamoDBMobile';
import {AmplifySignOut} from '@aws-amplify/ui-react';     //import der Funktionen die für auth genutzt werden
import Blank from './components/blank';

import PublicBilder from './components/bilder/PublicBilder';
import PrivateBilder from './components/bilder/PrivateBilder';

Amplify.configure(awsconfig)                        // Konfiguration von Amplify

function App() {
     
//Teil der gezeigt wird
  return (
    <div className="App">

      <BrowserRouter>
        <Headers />
        <Blank />
          <Switch>
            <Route path="/gipfeltabelle" component={DbTabelle} />
            <Route path="/gipfeltabelleMobile" component={DbTabelleMobile} />
            <Route path="/bilderfrei" component={PublicBilder} />
            <Route path="/bilderprivat" component={PrivateBilder} />
          </Switch>
      </BrowserRouter>
      <AmplifySignOut />

    </div>
  );
}

//zusätzlicher Export der Authenticator       
export default withAuthenticator(App, {
  signUpConfig: {
    hiddenDefaults: ['phone_number']
  }
});