/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGipfeltabelle = /* GraphQL */ `
  mutation CreateGipfeltabelle(
    $input: CreateGipfeltabelleInput!
    $condition: ModelGipfeltabelleConditionInput
  ) {
    createGipfeltabelle(input: $input, condition: $condition) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const updateGipfeltabelle = /* GraphQL */ `
  mutation UpdateGipfeltabelle(
    $input: UpdateGipfeltabelleInput!
    $condition: ModelGipfeltabelleConditionInput
  ) {
    updateGipfeltabelle(input: $input, condition: $condition) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const deleteGipfeltabelle = /* GraphQL */ `
  mutation DeleteGipfeltabelle(
    $input: DeleteGipfeltabelleInput!
    $condition: ModelGipfeltabelleConditionInput
  ) {
    deleteGipfeltabelle(input: $input, condition: $condition) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      image
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      image
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      image
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
