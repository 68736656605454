/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateGipfeltabelle = /* GraphQL */ `
  subscription OnCreateGipfeltabelle($owner: String!) {
    onCreateGipfeltabelle(owner: $owner) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const onUpdateGipfeltabelle = /* GraphQL */ `
  subscription OnUpdateGipfeltabelle($owner: String!) {
    onUpdateGipfeltabelle(owner: $owner) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const onDeleteGipfeltabelle = /* GraphQL */ `
  subscription OnDeleteGipfeltabelle($owner: String!) {
    onDeleteGipfeltabelle(owner: $owner) {
      id
      datum
      gipfel
      hoehe
      climp
      start
      region
      owner
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      description
      price
      image
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      description
      price
      image
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      description
      price
      image
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String!) {
    onCreateUser(owner: $owner) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String!) {
    onUpdateUser(owner: $owner) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String!) {
    onDeleteUser(owner: $owner) {
      id
      username
      avatar {
        bucket
        region
        key
      }
      owner
    }
  }
`;
