import * as React from 'react';
import './DataGrid.css';
import Amplify, {API, graphqlOperation } from '@aws-amplify/api';                  //import von aws-amplify || API und graphqloperations für fetchGipfel
import awsconfig from '../../aws-exports';              //import für die Einstellungen von amplify
import { listGipfeltabelles } from '../../graphql/queries';                       //import der Funktion listGipfeltabelles zum abrufen der Daten
import { useState, useEffect } from 'react';

import { DataGrid } from '@material-ui/data-grid';        //Test import für DataGrid
import FilterHdrIcon from '@material-ui/icons/FilterHdr';  //Berg Icon
import RowAdd from './AddRowDynamoDB';

Amplify.configure(awsconfig)                        // Konfiguration von Amplify

export default function DbTabelle(){

    const [gipfel, setGipfel] = useState( [] );                                      //Array zum setzen des use State
    
    useEffect(() => {                                                             //useEffect ist eine React function und wird bei jedem Rendern ausgeführt
        fetchGipfeltabelle();                                                       // dabei wird dann die Funktion fetchGipfeltabelle ausgeführt
    }, []);                                                                       // Das leere Array [] verhindert eine infinity loop da es nur ausgeführt wird wenn leer

    //Jedes mal wenn die Funktion aufgerufen wird werden die Daten von der DB geholt und in das obige Array von gipfel und setGipfel geschrieben
    const fetchGipfeltabelle = async () => {                                      
        try {
        const GipfelData = await API.graphql(graphqlOperation(listGipfeltabelles)) //die Funktion listGipfeltabelles wurde automatisch in graphql/queries.js angelegt und in der const gespeichert
        const GipfelList = GipfelData.data.listGipfeltabelles.items;              // zugriff auf die Daten von GipfelData durch GipfelList
        console.log('Gipfel Tabelle', GipfelList);                                // Log der Gipfellisten zum prüfen
        setGipfel(GipfelList)                                                     // update der State Variablen zum Nutzen im weiteren Verlauf
        } catch (error) {
            console.log('error on fetching Gipfel', error);
        }
    }

    //definieren von dem Datagrid und sammeln der Daten
    const columns = [
        
        { field: 'datum', headerName: 'Datum', flex: 100 },
        { field: 'gipfel', headerName: 'Gipfel', flex: 130 },
        { field: 'hoehe', headerName: 'Höhe in m', flex: 80 },
        { field: 'climp', headerName: 'Art der Besteigung', flex: 180 },
        { field: 'start', headerName: 'Startpunkt', flex: 250 },
        { field: 'region', headerName: 'Region', flex: 130 },

        ];

    const rows = gipfel

    

    //Teil der gezeigt wird
    return (
        <>
        
        <div className="HeadLine">
           <FilterHdrIcon fontSize="large" /> <h3> Gipfeltabelle mit den erfolgreich bestiegenen Gipfeln: </h3> 
        </div>  

        <div className="Test">
       
            <div style={{ height: 650, width: '100%', textAlign: 'center' }}>
            <DataGrid rows={rows} columns={columns} pageSize={10} />
            </div>
        </div>
        <RowAdd />
        
        </>
        
    );
  
    
}
